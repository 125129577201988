import React, {useEffect, useState} from 'react';
import {BarcodeDetector} from "barcode-detector";
import localStyles from './styles.module.scss'
import Button from "../Button";
import translate from "../../utils/translate";

interface Interface {
    readerBarCode: (barcode: string) => void;
    close: () => void;
}

const ScannerForm = ({readerBarCode, close}: Interface) => {

    const [cameraOn, setCameraOn] = useState<boolean>(false)
    const [ctx, setCtx] = useState<any>(null)
    const [ctxCanvasTemp, setCtxCanvasTemp] = useState<any>(null)
    const [video, setVideo] = useState<any>(null)
    const [canvas, setCanvas] = useState<any>(null)
    const [canvasTemp, setCanvasTemp] = useState<any>(null)


    function cropCanvasToImage() {

        // Задаємо координати і розміри області, яку будемо зрізати
        const cropX = 0;  // Ліва координата зрізу
        const cropY = canvas.height * 0.3;   // Верхня координата зрізу
        const cropWidth = canvas.width;  // Ширина зрізу
        const cropHeight = canvas.height * 0.5; // Висота зрізу

        // Встановлюємо розміри тимчасового канваса такі ж, як зрізана область
        canvasTemp.width = cropWidth;
        canvasTemp.height = cropHeight;
        // Малюємо зрізану частину на тимчасовий канвас
        ctxCanvasTemp.drawImage(canvas, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

        const barcodeDetector = new BarcodeDetector({
            formats: ["code_39", "codabar", "ean_13", "code_128"],
        });
        barcodeDetector.detect(canvasTemp).then(result => {
            if (result && Array.isArray(result) && result.length) {
                const code = result[0].rawValue;
                readerBarCode && readerBarCode(code)
                // alert(code)
            }
        })
    }

    function startCamera(video: any) {
        navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
                facingMode: 'environment',
                height: {ideal:1920},
                width: {ideal: 1920},
            }
        })
            .then(stream =>  {
                setCameraOn(true)
                return stream
            })
            .then((stream) => {
                video.srcObject = stream;
            })
            .catch((err) => {
                console.error('Помилка доступу до камери: ', err);
            });
    }

    useEffect(() => {

        const idInterval = setInterval(() => {
            if (cameraOn) {
                canvas.width = video.videoWidth
                canvas.height = video.videoHeight
                ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

                cropCanvasToImage()
            }
        }, 500)

        return () => {
            clearInterval(idInterval)
        }
    }, [cameraOn]);

    useEffect(() => {
        const video: any = document.getElementById('video');
        const canvas: any = document.getElementById('canvas');
        const canvas_temp: any = document.getElementById('canvas_temp');

        setVideo(video)
        setCanvas(canvas)
        setCanvasTemp(canvas_temp)
        setCtx(canvas.getContext('2d'))
        setCtxCanvasTemp(canvas_temp.getContext('2d'))
        startCamera(video)

        return () => {
            setCameraOn(false)
            if (video.srcObject && video.srcObject.getTracks()) {
                video.srcObject.getTracks().map((track: MediaStreamTrack) => track.stop())
            }
        }
    }, []);

    return (
        <div className={localStyles.container}>
            <video id="video" className={localStyles.video} autoPlay></video>
            <canvas id="canvas" className={localStyles.canvas} style={{display: 'none'}}></canvas>
            <canvas id="canvas_temp" style={{display: 'none'}}></canvas>
            <Button id={'close-scanner'}
                    background={'bg-danger'}
                    size={'large'}
                    text={translate('cancel')}
                    classes={localStyles.closeScanner}
                    click={() => close()}
            />
            <div className={localStyles.shadow1}></div>
            <div className={localStyles.shadow2}></div>
        </div>
    );
};

export default ScannerForm;
